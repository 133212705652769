/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/_styles.scss";
import "@/styles/settings.scss";
import "@/styles/main.scss";

// Composables
import { createVuetify } from "vuetify";

const customTheme = {
    dark: false,
    colors: {
        primary: "#00a2e6",
        secondary: "#fdc40e",
        default: "#706c68",
    },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    theme: {
        options: {
            customProperties: true,
        },
        defaultTheme: "customTheme",
        themes: {
            customTheme,
        },
    },
    defaults: {
        VBtn: {
            style: { textTransform: "none" },
        },
    },
    typography: {
        fontFamily: "Brandon Text, sans-serif",
    },
});
